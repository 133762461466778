@font-face {
  font-family: iran-sans;
  src: url("./IRANSansWeb\(FaNum\).ttf");
}
* {
  padding: 0;
  margin: 0;
  outline: none;
  font : {
    family: iran-sans;
  }
}
.appContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("./pic2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  direction: rtl;

  p {
    color: #fff;
    &:nth-child(1) {
      font : {
        size: 3rem;
      }
    }
    &:nth-child(2) {
      font : {
        size: 2.5rem;
      }
    }
  }

  .project {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    direction: ltr;
    p {
      font : {
        size: 1rem;
      }
      margin-top: 1rem;
    }
  }
}
