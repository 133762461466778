@font-face {
  font-family: iran-sans;
  src: url("./IRANSansWeb(FaNum).ttf");
}
* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: iran-sans;
}

.appContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("./pic2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  direction: rtl;
}
.appContainer p {
  color: #fff;
}
.appContainer p:nth-child(1) {
  font-size: 3rem;
}
.appContainer p:nth-child(2) {
  font-size: 2.5rem;
}
.appContainer .project {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  direction: ltr;
}
.appContainer .project p {
  font-size: 1rem;
  margin-top: 1rem;
}/*# sourceMappingURL=myStyle.module.css.map */